import React, { Component, Suspense } from 'react'

// import placeholder comonents START
import { useIntl } from "gatsby-plugin-intl"
import loadable from '@loadable/component'
import Preloader from './../../../../components/s4n/Preloader'
// forms
/*
import FormContactS4n from './../../../../components/forms/webform/contact_s4n'
import FormHeuresisHarmonogramZgloszenie from './../../../../components/forms/webform/heuresis_harmonogram_zgloszenie'
import FormHeuresisOcenaJakosci from './../../../../components/forms/webform/heuresis_ocena_jakosci_'
import HeuresisOutsourcingDiagnozaFi from './../../../../components/forms/webform/heuresis_outsourcing_diagnoza_fi'
import HeuresisZapytanieSzkolenieS from './../../../../components/forms/webform/heuresis_zapytanie_o_szkolenie_s'
import HeuresisZapytanieSzkolenieZ from './../../../../components/forms/webform/heuresis_zapytanie_o_szkolenie_z'
// other
import PromotedFrontPage from './../../../../components/s4n/Promoted/FrontPage/index'
// charts
import ChartHeuresisOcenaJakosci from './../../../../components/charts/heuresis_ocena_jakosci_'
*/
// import placeholder comonents STOP

// import { translation as FormContactS4nTranslation } from './../../../../components/s4n/layoutBlock'



const FORM_PREFIX = `s4n_form_`;
const CHART_PREFIX = `s4n_chart_`;

export default (oReplacement = {}) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    // Loadable components START
    // forms
    const FormContactS4n = loadable(() => import('./../../../../components/forms/standalone/contact_s4n'), {fallback: <Preloader />})
    // const FormContactS4n = loadable(() => import('./../../../../components/forms/webform/contact_s4n'), {fallback: <Preloader />})
    // const FormHeuresisHarmonogramZgloszenie = loadable(() => import('./../../../../components/forms/webform/heuresis_harmonogram_zgloszenie'), {fallback: <Preloader />})
    // const FormHeuresisOcenaJakosci = loadable(() => import('./../../../../components/forms/webform/heuresis_ocena_jakosci_'), {fallback: <Preloader />})
    // const HeuresisOutsourcingDiagnozaFi = loadable(() => import('./../../../../components/forms/webform/heuresis_outsourcing_diagnoza_fi'), {fallback: <Preloader />})
    // const HeuresisZapytanieSzkolenieS = loadable(() => import('./../../../../components/forms/webform/heuresis_zapytanie_o_szkolenie_s'), {fallback: <Preloader />})
    // const HeuresisZapytanieSzkolenieZ = loadable(() => import('./../../../../components/forms/webform/heuresis_zapytanie_o_szkolenie_z'), {fallback: <Preloader />})

    // other
    const PromotedFrontPage = loadable(() => import('./../../../../components/s4n/Promoted/FrontPage/index'), {fallback: <Preloader />})
    const Team = loadable(() => import('./../../../../components/s4n/Team/index-static'), {fallback: <Preloader />})
    // const Portfolio = loadable(() => import(`./../../../../components/s4n/Portfolio`));
    // const Subpages = loadable(() => import('./../../../../components/s4n/Menu/Subpages'), {fallback: <Preloader />})

    // charts
    // const ChartHeuresisOcenaJakosci = loadable(() => import('./../../../../components/charts/heuresis_ocena_jakosci_'), {fallback: <Preloader />})
    // Loadable components STOP

    // const DynamicComponent = loadable(props => import(`./${props.path}`))

    const replacement = {

        /**
         * oReplacement can contain:
         * [`[s4n_related_products]`]
         * [`[s4n_subpages]`]
         */
        ...oReplacement,

        // Loadable components START
        [`[${FORM_PREFIX}contact_s4n]`]: <FormContactS4n />,
        // [`[${FORM_PREFIX}heuresis_harmonogram_zgloszenie]`]: <FormHeuresisHarmonogramZgloszenie />,
        // [`[${FORM_PREFIX}heuresis_ocena_jakosci_]`]: <FormHeuresisOcenaJakosci />,
        // [`[${FORM_PREFIX}heuresis_outsourcing_diagnoza_fi]`]: <HeuresisOutsourcingDiagnozaFi />,
        // [`[${FORM_PREFIX}heuresis_zapytanie_o_szkolenie_s]`]: <HeuresisZapytanieSzkolenieS />,
        // [`[${FORM_PREFIX}heuresis_zapytanie_o_szkolenie_z]`]: <HeuresisZapytanieSzkolenieZ />,
        // [`[${CHART_PREFIX}heuresis_ocena_jakosci_]`]: <ChartHeuresisOcenaJakosci />,
        [`[s4n_front_page_promoted]`]: <PromotedFrontPage limit={3} />,

        [`[s4n_team]`]: <Team />,
        // [`[s4n_team]`]: <React.Fragment></React.Fragment>, // use empty replacement!

        // [`[s4n_team]`]: props => { const Component = loadable(() => import(`./../../../../components/s4n/Team/index-static`), {fallback: <Preloader />}); return <Component /> },
        // Loadable components STOP

        // @todo: check this
        // ['lazy_loaded']: React.lazy(() => import('./../../../../components/forms/webform-contact-s4n')),      

    }

    return replacement;

/*
    switch (htmlNode.children[0].data) {
        case '[webform_contact_s4n]':
            // Component = <FormContactS4n />;
            Component = React.lazy(() => import('./../../components/forms/webform-contact-s4n'));
            break;
        case '[webform_harmonogram_s4n]':
            Component = React.lazy(() => import('./../../components/forms/webform-harmonogram-s4n'));
            break;
        case '[webform_quality_check_s4n]':
            Component = React.lazy(() => import('./../../components/forms/webform-quality-check-s4n'));
            break;
        // ...
    }
*/
}