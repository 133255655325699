import React from "react"

// s4n
import _ from 'lodash'
import { useIntl } from "gatsby-plugin-intl"
import useProcessContentTags from './parseContentProcessor/useProcessContentTags'



export const useParseInlineImages = (node, wrap = false, oReplacement = {}) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    // node.body.value // striped HTML tags!!! more like raw?
    // node.body.processed // this field contains HTML entities encoded tags!!!
    const html = node.body ? node.body.value : node;

    // const { html } = props; // use when we use this a component and receive props as argument!
    // console.log(html); return;
    let htmlReactParserParsedHtml = useProcessContentTags(html, oReplacement, node);

    // CHECK IF WE HAVE WRAPPING .container element START
    if (false && wrap) {
        // const hasContainer = ReactDOM.findDOMNode(<instance-of-outermost-component />).getElementsByClassName('snap').length
        // const hasContainer = ReactDOM.findDOMNode(nodeBodyField).getElementsByClassName('container').length;

        // ReactDOM.findDOMNode(

        // const hasContainer = ReactDOM.findDOMNode(nodeBodyField).getElementsByClassName('container');
        // console.log(hasContainer)

        // console.log(nodeBodyField)

        // const hasContainerRecursive = (html) => {
        //     if (`undefined` !== typeof html.props.className && `container` === html.props.className) {
        //         return true;
        //     }
        //     return hasContainerRecursive(html.props.children);
        // }
        // const hasContainer = hasContainerRecursive(nodeBodyField);

        // React.Children.forEach(nodeBodyField, child => {
        //   const nestedChildren = new child.type(child.props).render();

        //   console.log(nestedChildren); // `FooTuple` children
        // });

        // console.log(hasContainer)
        
        // let snapCount = React.Children.toArray(nodeBodyField).filter((item) => {
        //   console.log(item)
        //   return item.props.className === 'container'
        // }).length;


        // class Abc extends React.Component {
        //   render() {
        //       return nodeBodyField
        //   }
        // };

        // const hasContainer = ReactDOM.findDOMNode(<Abc />).getElementsByClassName('container');
        // console.log(hasContainer)

        // const childrenWithWrapperDiv = React.Children.map(nodeBodyField, child => {
        //   console.log(child)
        //   return (
        //     <div className="some-component-special-class">{child}</div> 
        //   );
        // });

        // @see: https://stackoverflow.com/questions/32916786/react-children-map-recursively
        // @see: https://stackoverflow.com/questions/30971395/difference-between-react-component-and-react-element
        const recursiveMap = (children, callback) => {
            return React.Children.map(children, child => {
                if (!React.isValidElement(child)) {
                    return child;
                }

                if (child.props.children) {
                    child = React.cloneElement(child, {
                        children: recursiveMap(child.props.children, callback)
                    });
                }

                return callback(child);
            });
        }

        let classToBeFound = `container`;
        let hasContainer = false;
        recursiveMap(htmlReactParserParsedHtml, (child) => {
            if (`undefined` !== typeof child.props.className && child.props.className.includes(classToBeFound)) {
                hasContainer = true;
            }
        })

        // console.log(hasContainer)

        if (htmlReactParserParsedHtml.length && !hasContainer) {
            htmlReactParserParsedHtml = (
                <div className="container">
                    { htmlReactParserParsedHtml }
                </div>
            )
        }
    }
    // CHECK IF WE HAVE WRAPPING .container element STOP



    // @see: https://reactjs.org/docs/react-dom-server.html
    //htmlReactParserParsedHtml = ReactDOMServer.renderToString(htmlReactParserParsedHtml);

    // You can use the react-html-parser in case you don't want to use dangerouslySetInnerHTML attribute
    return htmlReactParserParsedHtml;
    // return renderToStaticMarkup(htmlReactParserParsedHtml);
}

// useParseInlineImages.propTypes = {
//     html: PropTypes.string.isRequired,
// }

// export default useParseInlineImages